import React from "react";
import { SelectInput } from 'react-admin';
const RoleInput = () => (
    <SelectInput label="Роль" source="role" choices={[
        { id: 'user', name: 'User' },
        { id: 'admin', name: 'Admin' }
    ]} />
);

export default RoleInput;
