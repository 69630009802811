import React from "react";
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    ReferenceField,
    EditButton,
    ReferenceInput,
    SelectInput,
    ShowButton,
    BooleanField,
    Filter } from 'react-admin';

const RuleAliasFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Id" source="id" defaultValue="" />
        <TextInput label="Имя" source="name" defaultValue="" />
        <ReferenceInput label="Правило" source="ruleId" reference="rules">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const RuleAliasList = (props) => (
    <List title="Алиасы" filters={<RuleAliasFilter />} {...props}>
        <Datagrid>
            <TextField source="id" label="id"/>
            <TextField label="Имя" source="name" defaultValue="" />
            <ReferenceField label="Правило" source="rule_id" reference="rules">
                <TextField source="name" />
            </ReferenceField>
            <BooleanField label="Активный" source="active" />
            <ShowButton label="Детали"/>
            <EditButton label="Править"/>
        </Datagrid>
    </List>
);
export default RuleAliasList;
