import React from 'react';
import {
    List,
    Show,
    TabbedShowLayout,
    Datagrid,
    TextField,
    TextInput,
    SimpleForm,
    Create,
    required,
    minLength,
    Edit,
    Tab,
    EditButton,
    ShowButton,
    DateField,
    DisabledInput,
    Filter } from 'react-admin';
import RoleInput from './roleInput'
const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Id" source="id" defaultValue="" />
        <TextInput label="Имя" source="name" defaultValue="" />
        <TextInput label="Ник" source="nickname" defaultValue="" />
    </Filter>
);

export const UserList = (props) => (
    <List title="администраторы" filters={<UserFilter />} {...props}>
        <Datagrid>
            <TextField source="id" label="id"/>
            <TextField label="Имя" source="name" defaultValue="" />
            <TextField label="Ник" source="nickname" defaultValue="" />
            <TextField label="Роль" source="role" defaultValue="" />
            <ShowButton label="Детали"/>
            <EditButton label="Править"/>
        </Datagrid>
    </List>
);

const UserTitle = ({ record }) => {
    return <span>{record ? `"${record.name}"` : ''}</span>;
};

export const UserShow = (props) => (
    <Show title={<UserTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="Общие">
                <TextField source="id" label="id"/>
                <TextField source="name" label="Имя"/>
                <TextField source="nickname" label="Ник"/>
                <TextField source="email" label="Email"/>
                <TextField label="Роль" source="role" defaultValue="" />
                <DateField label="Дата добавления" source="created_at" showTime={true}/>
                <DateField label="Последнее обновление" source="updated_at" showTime={true}/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required(), minLength(2)]} />
            <TextInput source="nickname" validate={[required(), minLength(2)]} />
            <TextInput source="email" validate={[required(), minLength(3)]} />
            <RoleInput/>
            <TextInput source="password" type="password" validate={[required(), minLength(6)]} />
        </SimpleForm>
    </Create>
);

export const UserEdit = (props) => (
    <Edit title={<UserTitle />} {...props}>
        <SimpleForm>
            <DisabledInput label="Id" source="id" />
            <TextInput source="name" validate={[required(), minLength(2)]} />
            <TextInput source="nickname" validate={[required(), minLength(2)]} />
            <TextInput source="email" validate={[required(), minLength(3)]} />
            <RoleInput/>
        </SimpleForm>
    </Edit>
);
