import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
} from 'react-admin';
import { API_URL } from './apiUrl'
import { stringify } from 'query-string';

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: field,
                order: order,
                page: JSON.stringify(page),
                per_page: JSON.stringify(perPage),
                filter: JSON.stringify(params.filter),
            };
            return { url: `${API_URL}/${resource}?${stringify(query)}`, options: {} };
        }
        case GET_ONE:
            return { url: `${API_URL}/${resource}/${params.id}`, options: {} };
        case GET_MANY: {
            const query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            return { url: `${API_URL}/${resource}?${stringify(query)}`, options: {} };
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: field,
                order: order,
                page: JSON.stringify(page),
                per_page: JSON.stringify(perPage),
                filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
            };
            //
            // const query = {
            //     sort: JSON.stringify([field, order]),
            //     page: JSON.stringify(page),
            //     perPage: JSON.stringify(perPage),
            //     filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
            // };
            return { url: `${API_URL}/${resource}?${stringify(query)}`, options: {} };
        }
        case UPDATE:
            return {
                url: `${API_URL}/${resource}/${params.id}`,
                options: { method: 'PUT', body: JSON.stringify(params.data) },
            };
        case CREATE:
            return {
                url: `${API_URL}/${resource}`,
                options: { method: 'POST', body: JSON.stringify(params.data) },
            };
        case DELETE:
            return {
                url: `${API_URL}/${resource}/${params.id}`,
                options: { method: 'DELETE' },
            };
        case DELETE_MANY:
            return {
                url: `${API_URL}/${resource}`,
                options: { method: 'DELETE', body: JSON.stringify({ "ids": params.ids})},
            };
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
    const { headers, json } = response;
    const access_token = headers.get('access-token');
    const expiry = headers.get('expiry');
    const token_type = headers.get('token-type');
    const client = headers.get('client');
    const uid = headers.get('uid');
    if (access_token) {
        localStorage.setItem('auth', JSON.stringify({ access_token, expiry, token_type, client, uid }));
    }

    switch (type) {
        case GET_LIST:
            return {
                data: json.map(x => x),
                total: parseInt(headers.get('X-Total-Count').split('/').pop(), 10),
            };
        case GET_MANY_REFERENCE:
            return {
                data: json.map(x => x),
                total: parseInt(headers.get('X-Total-Count').split('/').pop(), 10),
            };

        case CREATE:
            return { data: { ...params.data, id: json.id } };
        default:
            return { data: json };
    }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
    const { fetchJson } = fetchUtils;
    const { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
    options.headers = new Headers({ Accept: 'application/json' });

    const auth = JSON.parse(localStorage.getItem('auth'));
    options.headers.set('access-token', auth.access_token);
    options.headers.set('expiry', auth.expiry);
    options.headers.set('token-type', auth.token_type);
    options.headers.set('client', auth.client);
    options.headers.set('uid', auth.uid);

    return fetchJson(url, options)
        .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
};
