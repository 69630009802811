import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

export default () => (
    <div>
        <Card>
            <CardHeader title="Ссылка на выгрузку" />
            <CardContent>
                <a href="https://law.klops.me/api/v1/export">https://law.klops.me/api/v1/export</a>
            </CardContent>
        </Card>

    </div>
);
