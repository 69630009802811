import React from "react";
import {
    TextInput,
    SimpleForm,
    required,
    minLength,
    Edit,
    ReferenceInput,
    SelectInput,
    DisabledInput,
    BooleanInput
} from 'react-admin';

const RuleAliasTitle = ({ record }) => {
    return <span>{record ? `"${record.name}"` : ''}</span>;
};

export const RuleAliasEdit = (props) => (
    <Edit title={<RuleAliasTitle />} {...props}>
        <SimpleForm>
            <DisabledInput label="Id" source="id" />
            <TextInput source="name" validate={[required(), minLength(2)]} />
            <ReferenceInput label="Правило" source="rule_id" reference="rules">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <BooleanInput label="Активный" source="active"/>
        </SimpleForm>
    </Edit>
);

export default RuleAliasEdit;
