import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import { API_AUTH_URL } from './apiUrl'
export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(API_AUTH_URL, {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                const access_token = response.headers.get('access-token');
                const expiry = response.headers.get('expiry');
                const token_type = response.headers.get('token-type');
                const client = response.headers.get('client');
                const uid = response.headers.get('uid');

                localStorage.setItem('auth', JSON.stringify({ access_token, expiry, token_type, client, uid }));
                localStorage.setItem('role', response.headers.get('role'));
                return response.json();
            });
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('auth');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const status  = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject();
        return Promise.resolve()
    }

    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    }
    return Promise.resolve();
}
