import React from 'react';
import {
    List,
    Show,
    TabbedShowLayout,
    Datagrid,
    TextField,
    TextInput,
    SimpleForm,
    Create,
    required,
    minLength,
    BooleanField,
    LongTextInput,
    BooleanInput,
    Edit,
    Tab,
    EditButton,
    ShowButton,
    DateField,
    DisabledInput,
    Filter } from 'react-admin';

const CategoryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Id" source="id" defaultValue="" />
        <TextInput label="Имя" source="name" defaultValue="" />
        <TextInput label="Сообщение" source="notice" defaultValue="" />
    </Filter>
);

export const CategoryList = (props) => (
    <List title="Категории" filters={<CategoryFilter />} {...props}>
        <Datagrid>
            <TextField source="id" label="id"/>
            <TextField label="Имя" source="name" defaultValue="" />
            <TextField label="Сообщение" source="notice" defaultValue="" />
            <BooleanField label="Активность" source="active" />
            <ShowButton label="Детали"/>
            <EditButton label="Править"/>
        </Datagrid>
    </List>
);

const CategoryTitle = ({ record }) => {
    return <span>{record ? `"${record.name}"` : ''}</span>;
};

export const CategoryShow = (props) => (
    <Show title={<CategoryTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="Общие">
                <TextField source="id" label="id"/>
                <TextField source="name" label="Имя"/>
                <TextField label="Сообщение" source="notice" />
                <TextField label="Активность" source="active" />
                <DateField label="Дата добавления" source="created_at" showTime={true}/>
                <DateField label="Последнее обновление" source="updated_at" showTime={true}/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
export const CategoryCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required(), minLength(2)]} />
            <LongTextInput source="notice" validate={[required(), minLength(2)]} />
            <BooleanInput label="Активность" source="active" />
        </SimpleForm>
    </Create>
);

export const CategoryEdit = (props) => (
    <Edit title={<CategoryTitle />} {...props}>
        <SimpleForm>
            <DisabledInput source="id" />
            <TextInput source="name" validate={[required(), minLength(2)]} />
            <LongTextInput source="notice" validate={[required(), minLength(2)]} />
            <BooleanInput label="Активность" source="active" />
        </SimpleForm>
    </Edit>
);