import React from "react";
import {
    TextInput,
    SimpleForm,
    Create,
    required,
    minLength,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    TextField,
    BooleanInput
} from 'react-admin';

import { parse } from 'query-string';

export const RuleAliasCreate = (props) => {
    const {rule_id: rule_id_string} = parse(props.location.search);
    const rule_id = rule_id_string ? parseInt(rule_id_string, 10) : '';
    const redirect = rule_id ? `/rules/${rule_id}/show/rule_aliases` : false;
    return <Create {...props}>
        <SimpleForm
            defaultValue={{ rule_id: rule_id }}
            redirect={redirect}
        >
            <TextInput label='Текст' source="name" validate={[required(), minLength(2)]}/>
            {
                rule_id
                ?
                    <ReferenceField label="Правило" source="rule_id" reference="rules" >
                        <TextField source='name'/>
                    </ReferenceField>
                :
                    <ReferenceInput label="Правило" source="rule_id" reference="rules" validate={required()} >
                        <SelectInput optionText="name"/>
                    </ReferenceInput>
            }
            <BooleanInput label="Активный" source="active"/>
        </SimpleForm>
    </Create>;
};
export default RuleAliasCreate;
