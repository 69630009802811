import React from "react";
import {
    Show,
    TabbedShowLayout,
    TextField,
    ReferenceField,
    Tab,
} from 'react-admin';

const RuleAliasTitle = ({ record }) => {
    return <span>{record ? `"${record.name}"` : ''}</span>;
};

export const RuleAliasShow = (props) => (
    <Show title={<RuleAliasTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="Общие">
                <TextField source="id" label="id"/>
                <TextField source="name" label="Имя"/>
                <ReferenceField label="Правило" source="rule_id" reference="rules">
                    <TextField source="name" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
export default RuleAliasShow;
