import React from 'react';
import {
    List,
    Show,
    TabbedShowLayout,
    Datagrid,
    TextField,
    TextInput,
    SimpleForm,
    Create,
    required,
    minLength,
    BooleanField,
    ReferenceField,
    Edit,
    Tab,
    EditButton,
    LongTextInput,
    ReferenceInput,
    DateField,
    SelectInput,
    BooleanInput,
    ShowButton,
    DisabledInput,
    ReferenceManyField,
    Filter } from 'react-admin';
import AddAliasButton from './AddAliasButton';

const RuleFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Id" source="id" defaultValue="" />
        <TextInput label="Имя" source="name" defaultValue="" />
        <BooleanInput label="Активный" source="active" />
        <ReferenceInput label="Категория" source="categoryId" reference="categories">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const RuleList = (props) => (
    <List title="Правила" filters={<RuleFilter />} {...props}>
        <Datagrid>
            <TextField source="id" label="id"/>

            <TextField label="Имя" source="name" defaultValue="" />

            <ReferenceField label="Категория" source="category_id" reference="categories">
                <TextField source="name" />
            </ReferenceField>

            <BooleanField label="Активность" source="active" />

            <ShowButton label="Детали"/>

            <EditButton label="Править"/>
        </Datagrid>
    </List>
);

const RuleTitle = ({ record }) => {
    return <span>{record ? `"${record.name}"` : ''}</span>;
};

export const RuleShow = (props) => (
    <Show title={<RuleTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="Общие">
                <TextField source="id" label="id"/>
                <TextField source="name" label="Имя"/>
                <TextField label="Комментарий" source="comment" defaultValue="" />
                <ReferenceField label="Категория" source="category_id" reference="categories">
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField label="Активность" source="active" />
            </Tab>
            <Tab label="Алиасы" path="rule_aliases">
                <ReferenceManyField
                    addLabel={false}
                    reference="rule_aliases"
                    target="rule_id"
                >
                    <Datagrid>
                        <DateField source="created_at" />
                        <TextField source="name" />
                        <ShowButton />
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>
                <AddAliasButton />
            </Tab>
        </TabbedShowLayout>
    </Show>
);
export const RuleCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required(), minLength(2)]} />
            <LongTextInput source="comment" />
            <BooleanInput label="Активность" source="active" />
            <ReferenceInput label="Категория" source="category_id" reference="categories">
                <SelectInput optionText="name" />
            </ReferenceInput>

        </SimpleForm>
    </Create>
);

export const RuleEdit = (props) => (
    <Edit title={<RuleTitle />} {...props}>
        <SimpleForm>
            <DisabledInput label="Id" source="id" />
            <TextInput source="name" validate={[required(), minLength(2)]} />
            <LongTextInput source="comment" />
            <BooleanInput label="Активность" source="active" />
            <ReferenceInput label="Категория" source="category_id" reference="categories">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);
