import RuleAliasIcon from '@material-ui/icons/StarBorder';
import Create from './create';
import List from './list';
import Show from './show';
import Edit from './edit';

export default {
    list: List,
    show: Show,
    edit: Edit,
    create: Create,
    icon: RuleAliasIcon,
    options: { label: 'Алиасы' },
};
