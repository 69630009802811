import React from 'react';
import { Admin, Resource, MenuItemLink, AppBar, UserMenu, Layout } from 'react-admin';
import UserIcon from '@material-ui/icons/AccountBox';
import RuleIcon from '@material-ui/icons/Book';
import CategoryIcon from '@material-ui/icons/ShortText';
import { UserList, UserShow, UserCreate, UserEdit } from './users';
import { RuleList, RuleShow, RuleCreate, RuleEdit } from './rules';
import ruleAliases from './rule_aliases';
import { CategoryList, CategoryShow, CategoryCreate, CategoryEdit } from './categories';
import railsDataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';
import Dashboard from './dashboard';

const App = () => (
        <Admin dashboard={Dashboard} dataProvider={railsDataProvider} authProvider={authProvider} title="Terrorist detector">
            {permissions => [
                <Resource
                        name="categories"
                        options={{ label: 'Категории' }}
                        list={CategoryList}
                        icon={CategoryIcon}
                        show={CategoryShow}
                        create={CategoryCreate}
                        edit={CategoryEdit}
                />,
                <Resource
                        name="rules"
                        options={{ label: 'Правила' }}
                        list={RuleList}
                        icon={RuleIcon}
                        show={RuleShow}
                        create={RuleCreate}
                        edit={RuleEdit}
                />,
                <Resource name="rule_aliases" {...ruleAliases} />,
                permissions === 'admin'
                    ?
                        <Resource
                                name="users"
                                options={{ label: 'Пользователи' }}
                                list={UserList}
                                icon={UserIcon}
                                show={UserShow}
                                create={UserCreate}
                                edit={UserEdit}
                        />
                    : null,
            ]}
        </Admin>
);
export default App;
